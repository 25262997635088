import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FileFormat} from '@shared/core/enums/file-format';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {randomId} from '@shared/shared-module/utils/id.utils';
import {combineLatest, map, tap} from 'rxjs';
import {DocumentDto} from '../../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {DragAndDropDirective} from '../../directives/drag-and-drop.directive';
import {MarkRequiredDirective} from '../../directives/mark-required.directive';
import {SafeTranslatePipe} from '../../pipes/safe-translate.pipe';
import {FileUploadHandler, FileUploadService} from '../../services/file-upload/file-upload.service';
import {humanReadableByteSize, SIZE_1_MB} from '../../utils/file-size.utils';
import {NgChanges} from '../../utils/simple-changes-typed';
import {MessageType} from '../enums/messageType';
import {FormStepperNavigationComponent} from '../form-stepper-navigation/form-stepper-navigation.component';
import {MsaContentNoticeComponent} from '../msa-content-notice/msa-content-notice.component';
import {MsaFileOverviewComponent} from '../msa-file-overview/msa-file-overview.component';
import {MsaProgressBarComponent} from '../msa-progressbar/msa-progressbar.component';

const SIZE_10_MB = 10 * SIZE_1_MB;

@Component({
  selector: 'msa-file-upload',
  templateUrl: './msa-file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [FileUploadService],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    DragAndDropDirective,
    MatFormFieldModule,
    FormStepperNavigationComponent,
    MsaFileOverviewComponent,
    MarkRequiredDirective,
    SafeTranslateDirective,
    MsaContentNoticeComponent,
    SafeTranslatePipe,
    MsaProgressBarComponent
  ]
})
export class MsaFileUploadComponent<T extends DocumentDto> implements OnChanges {
  @Input() documentTitle = 'i18n.upload-container.title';
  @Input() description = 'i18n.upload-container.description';

  @Input() uploadedTitle = 'i18n.upload-container.dropzone.fileToUpload';
  @Input() fileRequiredText = 'i18n.upload-container.file-required';
  @Input() fileUploadHandler!: FileUploadHandler<T>;
  public acceptedFormats = input<FileFormat[]>([
    FileFormat.PDF,
    FileFormat.PNG,
    FileFormat.JPG,
    FileFormat.TIFF,
    FileFormat.BMP,
    FileFormat.HEIF
  ]);
  public maxNumUploads = input(10);
  @Input() maxFileSizeBytes: number = SIZE_10_MB;
  public uploadedDocuments = input<T[]>([]);
  @Input() enableDelete: boolean = false;
  @Input() control: FormControl<string[]> = new FormControl([], {
    nonNullable: true
  });
  @Input() s3BucketType!: 'PISA' | 'MILO';

  @Output() remove = new EventEmitter<string>();
  @Output() download = new EventEmitter<DocumentDto>();
  @Output() add = new EventEmitter<string>();

  // we need a random id, in case there are multiple file upload components
  public fileInputId = computed(() => `fileupload-${randomId()}`);
  public fileUploadService = inject(FileUploadService);
  public acceptedFileFormatsForInput = computed(() =>
    this.acceptedFormats()
      .map(format => `.${format.toUpperCase()}`)
      .join(',')
  );
  public acceptedFileFormatsText = computed(() =>
    this.acceptedFormats()
      .map(format => `${format.toUpperCase()}`)
      .join(', ')
  );
  public readonly MessageType = MessageType;
  canUpload = computed(() => this.uploadedDocuments().length < this.maxNumUploads());
  disabledUpload$ = combineLatest([toObservable(this.canUpload), this.fileUploadService.hasPendingUpload$]).pipe(
    map(([canUpload, hasPendingUpload]) => {
      return !canUpload || hasPendingUpload;
    })
  );
  protected readonly humanReadableByteSize = humanReadableByteSize;
  private destroyRef = inject(DestroyRef);

  ngOnChanges(changes: NgChanges<MsaFileUploadComponent<T>>) {
    if (changes.fileUploadHandler) {
      this.fileUploadService.registerUploadHandler(this.fileUploadHandler);
      this.fileUploadService.registerDocumentList(this.uploadedDocuments());
      this.fileUploadHandler
        .getResetStateTrigger()
        .pipe(
          tap(() => this.fileUploadService.resetUploadState()),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  uploadFiles(fileList: FileList | null): void {
    if (!fileList) throw new Error('File list is null. Cannot upload anything!');
    if (fileList.length === 0) return;

    const uploadingDocuments = [];
    for (let i = 0; i < fileList.length; i++) {
      uploadingDocuments.push(fileList.item(i)!);
    }

    this.fileUploadService.uploadFiles(uploadingDocuments, this.s3BucketType, {
      maxNumUploads: this.maxNumUploads(),
      maxFileSizeBytes: this.maxFileSizeBytes
    });
  }

  removeDocument(documentId: string) {
    this.fileUploadService.resetUploadState();
    this.remove.emit(documentId);
  }

  downloadDocument(document: DocumentDto) {
    this.download.emit(document);
  }
}
