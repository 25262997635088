import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {humanReadableByteSize} from '@shared/shared-module/utils/file-size.utils';
import {BehaviorSubject, catchError, EMPTY, finalize, from, map, mergeMap, Observable, Subscription, tap} from 'rxjs';
import {DocumentDto} from '../../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {Translatable} from '../../../../../projects/admin-query/src/app/stores/selectors/edit-request-state.selectors';
import {isDefined} from '../../utils/is-defined';
import {ConsoleLoggingService} from '../logging/console-logging.service';

export interface FileUploadHandler<T> {
  upload$(file: File, s3BucketType: 'PISA' | 'MILO'): Observable<HttpEvent<T>>;

  cancelUpload(): void;

  handleUploadError(error: unknown, file: File): UploadError[];

  getResetStateTrigger(): Observable<void>;
}

export interface UploadInfo {
  fileName: string;
  progress: number;
  numUploads: number;
  numInQueue: number;
}

export interface UploadError {
  fileName?: string;
  error: Translatable;
}

export interface UploadConstraints {
  maxNumUploads?: number;
  maxFileSizeBytes?: number;
}

/**
 * Used to represent component state and orchestrating upload
 */
@Injectable()
export class FileUploadService<T> {
  public currentUploadInfo$ = new BehaviorSubject<UploadInfo | null>(null);

  public hasPendingUpload$ = this.currentUploadInfo$.pipe(map(uploadInfo => uploadInfo !== null));
  public currentUploadProgress$ = this.currentUploadInfo$.pipe(
    map(uploadInfo => (uploadInfo ? uploadInfo.progress : null))
  );

  public uploadErrors$ = new BehaviorSubject<UploadError[]>([]);

  private uploadHandler: FileUploadHandler<T> | null = null;
  private uploadedDocuments: DocumentDto[] | null = null;
  private uploadSubscription: Subscription | null = null;
  private uploadErrors: UploadError[] = [];

  constructor(
    private destroyRef: DestroyRef,
    private loggingService: ConsoleLoggingService
  ) {}

  public registerUploadHandler(handler: FileUploadHandler<T>): void {
    this.uploadHandler = handler;
  }

  public registerDocumentList(uploadedDocuments: DocumentDto[]): void {
    this.uploadedDocuments = uploadedDocuments;
  }

  public cancelUpload(): void {
    this.uploadSubscription?.unsubscribe();
    this.currentUploadInfo$.next(null);
    this.uploadHandler?.cancelUpload();
  }

  public uploadFiles(files: File[], s3BucketType: 'PISA' | 'MILO', uploadOptions?: UploadConstraints): void {
    if (!this.uploadHandler) throw new Error('No upload handler is registered');
    if (files.length === 0) return;

    this.resetUploadState();

    if (uploadOptions && !this.verifyUploadConstraints(files, uploadOptions)) {
      return;
    }

    this.uploadSubscription = from(files)
      .pipe(
        mergeMap((file, index) => this.getUploadObservable$(file, index + 1, files.length, s3BucketType), 1), // 1 indicates only one concurrent upload
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  resetUploadState(): void {
    this.resetUploadInfo();
    this.uploadErrors = [];
    this.uploadErrors$.next([]);
  }

  private verifyUploadConstraints(files: File[], uploadConstraints: UploadConstraints): boolean {
    if (isDefined(uploadConstraints.maxNumUploads) && files.length > uploadConstraints.maxNumUploads) {
      this.addErrorMessage({
        text: 'i18n.upload-container.message.slot-out-of-bounds',
        params: {maxSlots: uploadConstraints.maxNumUploads}
      });
      return false;
    }

    if (isDefined(uploadConstraints.maxFileSizeBytes)) {
      const someFileTooLarge = files.some(file => {
        if (file.size > uploadConstraints.maxFileSizeBytes!) {
          this.addErrorMessage({
            text: 'i18n.upload-container.message.document-out-of-size',
            params: {size: humanReadableByteSize(uploadConstraints.maxFileSizeBytes!)}
          });
          return true;
        }

        return false;
      });

      if (someFileTooLarge) return false;
    }

    return true;
  }

  private getUploadObservable$(
    file: File,
    numInQueue: number,
    numUploads: number,
    s3BucketType: 'PISA' | 'MILO'
  ): Observable<HttpEvent<T>> {
    if (!this.uploadHandler) throw new Error('No upload handler is registered');
    if (!this.uploadedDocuments) throw new Error('No uploaded documents space registered');

    return this.uploadHandler.upload$(file, s3BucketType).pipe(
      tap((httpEvent: HttpEvent<T>) => {
        if (httpEvent.type === HttpEventType.UploadProgress) {
          this.currentUploadInfo$.next({
            fileName: file.name,
            progress: httpEvent.total ? (httpEvent.loaded / httpEvent.total) * 100 : 0,
            numInQueue,
            numUploads
          });
        }
      }),
      catchError((err: unknown) => {
        this.loggingService.error(err);
        this.resetUploadInfo();
        this.uploadErrors.push(...this.uploadHandler!.handleUploadError(err, file));

        return EMPTY;
      }),
      finalize(() => {
        this.uploadErrors$.next(this.uploadErrors);
        this.resetUploadInfo();
      })
    );
  }

  private addErrorMessage(error: Translatable, file?: File): void {
    const currentErrors = this.uploadErrors$.getValue();
    this.uploadErrors$.next([...currentErrors, {fileName: file?.name, error: error}]);
  }

  private resetUploadInfo(): void {
    this.currentUploadInfo$.next(null);
  }
}
