<h2 class="msa-text-field-set" [translate]="documentTitle" [appMarkRequired]="control!"></h2>
<div class="tw-mt-12"></div>

<label class="msa-text tw-mb-18" [translate]="description"></label>

@if (!canUpload()) {
  <app-msa-content-notice [type]="MessageType.Info">
    <p [innerHTML]="'i18n.document.maxNumUploadsReached' | safeTranslate: { maxNumUploads: maxNumUploads() }"></p>
  </app-msa-content-notice>
  <div class="tw-mt-18"></div>
} @else {
  <div
    class="tw-relative tw-z-10 tw-flex tw-flex-col tw-p-15 d:tw-p-30"
    [acceptedFormats]="acceptedFormats()"
    (filesChangeEmiter)="fileUploadService.uploadFiles($event, s3BucketType)"
    appFileDragDrop
  >
    <input
      class="tw-pointer-events-auto tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-cursor-pointer tw-opacity-0"
      #fileInput
      [id]="fileInputId()"
      [accept]="acceptedFileFormatsForInput()"
      [disabled]="disabledUpload$ | async"
      (change)="uploadFiles(fileInput.files)"
      type="file"
      multiple
    />

    @if (fileUploadService.currentUploadProgress$ | async; as progress) {
      <ng-container *ngTemplateOutlet="progressBar; context: { progress }" />
    }

    @if ((fileUploadService.hasPendingUpload$ | async) === false) {
      <label
        class="msa-text tw-pointer-events-none tw-flex tw-items-center tw-justify-center tw-gap-12 tw-text-navy-light-2 d:tw-flex-col"
        [for]="fileInputId()"
      >
        <mat-icon>cloud_upload</mat-icon>
        <p>{{ "i18n.upload-container.dropzone.label" | safeTranslate }}</p>
      </label>
    } @else {
      @if (fileUploadService.currentUploadInfo$ | async; as uploadInfo) {
        <label for="fileInput">
          {{
            "i18n.upload-container.uploading"
              | translate
                : {
                    fileName: uploadInfo.fileName,
                    numInQueue: uploadInfo.numInQueue,
                    numUploads: uploadInfo.numUploads,
                  }
          }}
        </label>
      }
    }
  </div>

  <div class="tw-mt-12"></div>

  <p class="msa-text-small tw-mb-12">
    {{
      "i18n.upload-container.dropzone.formats"
        | translate
          : {
              formats: acceptedFileFormatsText(),
              maxNumDocuments: maxNumUploads(),
              maxFileSize: humanReadableByteSize(this.maxFileSizeBytes),
            }
    }}
  </p>

  @for (uploadError of fileUploadService.uploadErrors$ | async; track uploadError.fileName) {
    <p class="msa-text-small tw-text-vermillion-light-2">
      @if (uploadError.fileName) {
        <span>{{ uploadError.fileName }}:</span>
      }
      {{ uploadError.error.text | translate: uploadError.error.params }}
    </p>
  }

  <ng-template #progressBar let-progress="progress">
    @if (progress !== null) {
      <msa-progressbar [canCancel]="true" [progress]="progress" (cancel)="fileUploadService.cancelUpload()" />
    }
  </ng-template>
}

<msa-file-overview
  [uploadedDocuments]="uploadedDocuments()"
  [enableDelete]="enableDelete"
  (remove)="removeDocument($event)"
  (download)="downloadDocument($event)"
/>
